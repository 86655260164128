// src/components/DataTransformation.tsx
import React, { useState } from 'react';
import { Toggle, Slider, Select } from '../components/ui';

interface DataTransformationProps {
  sourceData?: any;
  destinationType?: string;
  onChange?: (config: TransformationConfig) => void;
}

interface Column {
  name: string;
  type: string;
}


// Mock preview data
const mockPreviewData = [
  {
    order_id: 'SHO123',
    customer_id: 'CUST456',
    product_id: 'PROD789',
    order_date: '2024-01-15',
    amount: 99.99,
    status: 'completed',
    amazon_order_id: 'AMZ987',
    purchase_date: '2024-01-15',
    total_amount: 89.99,
    order_status: 'shipped'
  },
  {
    order_id: 'SHO124',
    customer_id: 'CUST457',
    product_id: 'PROD790',
    order_date: '2024-01-16',
    amount: 149.99,
    status: 'pending',
    amazon_order_id: 'AMZ988',
    purchase_date: '2024-01-16',
    total_amount: 139.99,
    order_status: 'processing'
  }
];

const mockColumns: Column[] = [
  { name: 'id', type: 'number' },
  { name: 'name', type: 'string' },
  { name: 'email', type: 'string' },
  { name: 'created_at', type: 'date' },
  { name: 'amount', type: 'number' },
  { name: 'status', type: 'string' }
];

interface TransformationConfig {
  removeDuplicates: boolean;
  joinConfig: {
    enabled: boolean;
    secondarySource: string;
    joinType: 'inner' | 'left' | 'right' | 'full';
    joinKey: string;
  };
  currencyConversion: {
    enabled: boolean;
    from: string;
    to: string;
    columns: string[];
  };
  dateFormat: {
    enabled: boolean;
    format: string;
    columns: string[];
  };
  nullHandling: {
    enabled: boolean;
    method: 'remove' | 'fill_zero' | 'fill_mean' | 'fill_custom';
    customValue?: string;
  };
  dataType: {
    enabled: boolean;
    columns: Record<string, string>;
  };
  renameColumns: {
    enabled: boolean;
    mappings: Record<string, string>;
  };
  roundNumbers: {
    enabled: boolean;
    decimals: number;
    columns: string[];
  };
  stringOperations: {
    enabled: boolean;
    operations: Array<{
      column: string;
      type: 'uppercase' | 'lowercase' | 'trim' | 'replace';
      value?: string;
    }>;
  };
}

export const DataTransformation: React.FC<DataTransformationProps> = ({
  sourceData,
  destinationType,
  onChange
}) => {
  const [config, setConfig] = useState<TransformationConfig>({
    removeDuplicates: false,
    joinConfig: {
      enabled: false,
      secondarySource: 'amazon',
      joinType: 'inner',
      joinKey: 'customer_id'
    },
    currencyConversion: {
      enabled: false,
      from: 'USD',
      to: 'EUR',
      columns: []
    },
    dateFormat: {
      enabled: false,
      format: 'YYYY-MM-DD',
      columns: []
    },
    nullHandling: {
      enabled: false,
      method: 'remove'
    },
    dataType: {
      enabled: false,
      columns: {}
    },
    renameColumns: {
      enabled: false,
      mappings: {}
    },
    roundNumbers: {
      enabled: false,
      decimals: 2,
      columns: []
    },
    stringOperations: {
      enabled: false,
      operations: []
    }
  });

  const [showPreview, setShowPreview] = useState(false);


  const handleNestedToggleChange = (
    parentKey: keyof TransformationConfig,
    value: boolean
  ) => {
    setConfig(prev => {
      const parentValue = prev[parentKey];
      if (typeof parentValue === 'object' && parentValue !== null && 'enabled' in parentValue) {
        return {
          ...prev,
          [parentKey]: {
            ...(parentValue as object),
            enabled: value
          }
        };
      }
      return {
        ...prev,
        [parentKey]: value
      };
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold mb-6">Data Transformation</h2>
      
      <div className="space-y-6">
        {/* Join Configuration */}
        <div className="p-4 border rounded-lg">
          <div className="flex items-center justify-between mb-3">
            <label className="font-medium">Join with Another Source</label>
            <Toggle
              checked={config.joinConfig.enabled}
              onChange={(value: boolean) => handleNestedToggleChange('joinConfig', value)}
            />
          </div>
          {config.joinConfig.enabled && (
            <div className="space-y-4">
              <Select
                value={config.joinConfig.secondarySource}
                onChange={(value: string) => setConfig(prev => ({
                  ...prev,
                  joinConfig: { ...prev.joinConfig, secondarySource: value }
                }))}
                options={[
                  { label: 'Amazon', value: 'amazon' },
                  { label: ' ', value: 'shopify' }
                ]}
                label="Secondary Data Source"
              />
              <Select
                value={config.joinConfig.joinType}
                onChange={(value: string) => setConfig(prev => ({
                  ...prev,
                  joinConfig: { ...prev.joinConfig, joinType: value as any }
                }))}
                options={[
                  { label: 'Inner Join', value: 'inner' },
                  { label: 'Left Join', value: 'left' },
                  { label: 'Right Join', value: 'right' },
                  { label: 'Full Join', value: 'full' }
                ]}
                label="Join Type"
              />
              <Select
                value={config.joinConfig.joinKey}
                onChange={(value: string) => setConfig(prev => ({
                  ...prev,
                  joinConfig: { ...prev.joinConfig, joinKey: value }
                }))}
                options={[
                  { label: 'Customer ID', value: 'customer_id' },
                  { label: 'Product ID', value: 'product_id' }
                ]}
                label="Join Key"
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Remove Duplicates */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between">
              <label className="font-medium">Remove Duplicates</label>
              <Toggle
                checked={config.removeDuplicates}
                onChange={(value: boolean) => handleNestedToggleChange('removeDuplicates', value)}
              />
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Remove duplicate rows from the dataset
            </p>
          </div>

          {/* Currency Conversion - con selezione colonne */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Currency Conversion</label>
              <Toggle
                checked={config.currencyConversion.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('currencyConversion', value)}
              />
            </div>
            {config.currencyConversion.enabled && (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-2">
                  <Select
                    value={config.currencyConversion.from}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      currencyConversion: { ...prev.currencyConversion, from: value }
                    }))}
                    options={[
                      { label: 'USD', value: 'USD' },
                      { label: 'EUR', value: 'EUR' },
                      { label: 'GBP', value: 'GBP' }
                    ]}
                    label="From"
                  />
                  <Select
                    value={config.currencyConversion.to}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      currencyConversion: { ...prev.currencyConversion, to: value }
                    }))}
                    options={[
                      { label: 'USD', value: 'USD' },
                      { label: 'EUR', value: 'EUR' },
                      { label: 'GBP', value: 'GBP' }
                    ]}
                    label="To"
                  />
                </div>
                {/* Selezione colonne per Currency */}
                <div>
                  <label className="text-sm text-gray-500">Apply to columns</label>
                  <Select
                    value={config.currencyConversion.columns[0] || ''}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      currencyConversion: {
                        ...prev.currencyConversion,
                        columns: [value]
                      }
                    }))}
                    options={mockColumns
                      .filter(col => col.type === 'number')
                      .map(col => ({
                        label: col.name,
                        value: col.name
                      }))}
                    label="Select columns"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Date Format - con selezione colonne */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Date Format</label>
              <Toggle
                checked={config.dateFormat.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('dateFormat', value)}
              />
            </div>
            {config.dateFormat.enabled && (
              <div className="space-y-4">
                <Select
                  value={config.dateFormat.format}
                  onChange={(value: string) => setConfig(prev => ({
                    ...prev,
                    dateFormat: { ...prev.dateFormat, format: value }
                  }))}
                  options={[
                    { label: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
                    { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
                    { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' }
                  ]}
                  label="Format"
                />
                {/* Selezione colonne per Date */}
                <div>
                  <label className="text-sm text-gray-500">Apply to columns</label>
                  <Select
                    value={config.dateFormat.columns[0] || ''}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      dateFormat: {
                        ...prev.dateFormat,
                        columns: [value]
                      }
                    }))}
                    options={mockColumns
                      .filter(col => col.type === 'date')
                      .map(col => ({
                        label: col.name,
                        value: col.name
                      }))}
                    label="Select columns"
                  />
                </div>
              </div>
            )}
          </div>

	  {/* Null Handling */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Handle Null Values</label>
              <Toggle
                checked={config.nullHandling.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('nullHandling', value)}
              />
            </div>
            {config.nullHandling.enabled && (
              <Select
                value={config.nullHandling.method}
                onChange={(value: string) => setConfig(prev => ({
                  ...prev,
                  nullHandling: { ...prev.nullHandling, method: value as any }
                }))}
                options={[
                  { label: 'Remove rows', value: 'remove' },
                  { label: 'Fill with zero', value: 'fill_zero' },
                  { label: 'Fill with mean', value: 'fill_mean' },
                  { label: 'Custom value', value: 'fill_custom' }
                ]}
                label="Method"
              />
            )}
          </div>

          {/* Round Numbers - con selezione colonne */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">Round Numbers</label>
              <Toggle
                checked={config.roundNumbers.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('roundNumbers', value)}
              />
            </div>
            {config.roundNumbers.enabled && (
              <div className="space-y-4">
                <Slider
                  min={0}
                  max={6}
                  value={config.roundNumbers.decimals}
                  onChange={(value: number) => setConfig(prev => ({
                    ...prev,
                    roundNumbers: { ...prev.roundNumbers, decimals: value }
                  }))}
                  label="Decimal places"
                />
                {/* Selezione colonne per Round Numbers */}
                <div>
                  <label className="text-sm text-gray-500">Apply to columns</label>
                  <Select
                    value={config.roundNumbers.columns[0] || ''}
                    onChange={(value: string) => setConfig(prev => ({
                      ...prev,
                      roundNumbers: {
                        ...prev.roundNumbers,
                        columns: [value]
                      }
                    }))}
                    options={mockColumns
                      .filter(col => col.type === 'number')
                      .map(col => ({
                        label: col.name,
                        value: col.name
                      }))}
                    label="Select columns"
                  />
                </div>
              </div>
            )}
          </div>

          {/* String Operations */}
          <div className="p-4 border rounded-lg">
            <div className="flex items-center justify-between mb-3">
              <label className="font-medium">String Operations</label>
              <Toggle
                checked={config.stringOperations.enabled}
                onChange={(value: boolean) => handleNestedToggleChange('stringOperations', value)}
              />
            </div>
            {config.stringOperations.enabled && (
              <Select
                value={config.stringOperations.operations[0]?.type || 'uppercase'}
                onChange={(value: string) => setConfig(prev => ({
                  ...prev,
                  stringOperations: {
                    ...prev.stringOperations,
                    operations: [{ column: '', type: value as any }]
                  }
                }))}
                options={[
                  { label: 'Convert to uppercase', value: 'uppercase' },
                  { label: 'Convert to lowercase', value: 'lowercase' },
                  { label: 'Trim whitespace', value: 'trim' },
                  { label: 'Replace text', value: 'replace' }
                ]}
                label="Operation"
              />
            )}
          </div>
        </div>

        <div className="mt-6">
          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              onClick={() => setShowPreview(!showPreview)}
            >
              {showPreview ? 'Hide Preview' : 'Preview Transformation'}
            </button>
          </div>

          {showPreview && (
            <div className="mt-6">
              <h3 className="text-lg font-medium mb-4">Preview Results</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {Object.keys(mockPreviewData[0]).map((header) => (
                        <th
                          key={header}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {mockPreviewData.map((row, idx) => (
                      <tr key={idx}>
                        {Object.values(row).map((value, cellIdx) => (
                          <td
                            key={cellIdx}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};